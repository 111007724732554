export function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

export function setTheme(theme = 'light') {
    const themes = {
        light: {
            '--logo-url': 'url(/img/intraquiz-logo-light.png?v=1)',
            '--bg-color': '#f4f5f7',
            '--bg-color-transparent': 'rgba(255, 255, 253, 0.8)',
            '--theme-color': '#f4f5f7',
            '--bg-hover': 'rgba(0, 0, 0, 0.05)',
            '--surface-bg-color': '#fffffc',
            '--divider-color': '#d9dce4',
            '--btn-bg-color': '#7701ff',
            '--btn-bg-hover-color': '#5f00cd',
            '--disabled-bg-color': '#d9dce1',
            '--text-primary-color': '#fffffc',
            '--text-secondary-color': '#101828',
            '--text-tertiary-color': '#a5acbe',
            '--alert-color': '#ffbe08',
            '--error-color': '#ff0c0c',
            '--success-color': '#57d9b7',
            '--accent-color': '#7701ff'
        },
        dark: {
            '--logo-url': 'url(/img/intraquiz-logo-dark.png?v=1)',
            '--bg-color': '#1a1125',
            '--bg-color-transparent': 'rgba(26, 17, 37, 0.8)',
            '--theme-color': '#1a1125',
            '--bg-hover': 'rgba(255, 255, 255, 0.05)',
            '--surface-bg-color': '#261d32',
            '--divider-color': '#31283f',
            '--btn-bg-color': '#6f01ec',
            '--btn-bg-hover-color': '#785fdc',
            '--disabled-bg-color': '#3f334b',
            '--text-primary-color': '#fffffc',
            '--text-secondary-color': '#e6d9f5',
            '--text-tertiary-color': '#7b6f98',
            '--alert-color': '#ffbe08',
            '--error-color': '#bc0c0d',
            '--success-color': '#43a188',
            '--accent-color': '#8b63fe'
        }
    };

    if (theme !== "light" && theme !== "dark") {
        theme = "light";
    }

    Object.entries(themes[theme]).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
    });

    document.querySelector("meta[name=theme-color]").setAttribute('content', themes[theme]['--theme-color']);
}

export function setBackground(url = null, theme = 'light') {
    let body = document.querySelector('body');
    if (url) {
        let bgImage = '';
        if (theme == 'dark') {
            bgImage += 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ';
        } else {
            bgImage += 'linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), ';
        }
        bgImage += 'url("' + url + '")';
        body.style.backgroundImage = bgImage;
        body.style.backgroundRepeat = 'no-repeat';
        body.style.backgroundSize = 'cover';
        // body.style.webkitBackgroundSize = 'cover';
        body.style.backgroundAttachment = 'fixed';
        body.style.backgroundPosition = 'top center';
        // body.style.minHeight = '100vh';
        // body.style.minWidth = '100vw';
    } else {
        body.style.backgroundImage = 'none';
        body.style.backgroundRepeat = 'repeat';
        body.style.backgroundSize = 'auto';
        body.style.backgroundAttachment = 'scroll';
        body.style.backgroundPosition = '0% 0%';
    }
}

export function formatNumber(x, noFloat = false) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (noFloat) {
        return parts[0];
    }
    return parts.join(",");
}

export function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    R = Math.round(R / 10) * 10
    G = Math.round(G / 10) * 10
    B = Math.round(B / 10) * 10

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export function adjustColor(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export function getBackSite(isTest = false) {
    return isTest ? 'http://syfer.test:8888' : 'https://intraquiz.ru';
}

export function getQuizSite(isTest = false) {
    return isTest ? 'http://localhost:3000' : 'https://app.intraquiz.ru';
}

export function getWsSite(isTest = false) {
    return isTest ? 'ws://localhost:2346' : 'wss://intraquiz.ru:2346';
}

export function fallbackCopyTextToClipboard(text) {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let res = null;
    try {
        let successful = document.execCommand("copy");
        let msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
        res = true;
    } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
        res = false;
    }

    document.body.removeChild(textArea);

    return res;
}

export function getOriginUrl() {
    let href = document.location.href;
    let referrer = document.referrer;
    if (window.parent != window) {
        return referrer;
    } else {
        return href;
    }
}

export function copy(obj) {
    function copyProps(clone) {
        for (let key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                clone[key] = copy(obj[key]);
            }
        }
    }

    /**
    * Создание иммутабельной копии объекта
    * @return {Object}
    */
    function cloneObj() {
        let clone = {};
        copyProps(clone);
        return clone;
    }

    /**
    * Создание иммутабельной копии массива
    * @return {Array}
    */
    function cloneArr() {
        return obj.map(function (item) {
            return copy(item);
        });
    }

    /**
    * Создание иммутабельной копии Map
    * @return {Map}
    */
    function cloneMap() {
        let clone = new Map();
        for (let [key, val] of obj) {
            clone.set(key, copy(val));
        }
        return clone;
    }

    /**
    * Создание иммутабельной копии Set
    * @return {Set}
    */
    function cloneSet() {
        let clone = new Set();
        for (let item of obj) {
            clone.add(copy(item));
        }
        return clone;
    }

    /**
    * Создание иммутабельной копии функции
    * @return {Function}
    */
    function cloneFunction() {
        let clone = obj.bind(this);
        copyProps(clone);
        return clone;
    }

    // Получение типа объекта
    let type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

    // Возвращаем копию в зависимости от типа исходных данных
    if (type === 'object') return cloneObj();
    if (type === 'array') return cloneArr();
    if (type === 'map') return cloneMap();
    if (type === 'set') return cloneSet();
    if (type === 'function') return cloneFunction();

    return obj;
}