import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Quiz from './pages/Quiz';
import Page404 from './pages/Page404';
import Join from './pages/Join';

const root = ReactDOM.createRoot(document.getElementById('root'));
window.settings = {
    dev_mode: 0,
};
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/join" element={<Join />} />
            <Route path="q" element={<Quiz />}>
                <Route path=":quizId" element={<div></div>} />
            </Route>
            <Route path="*" element={<Page404 />} />
        </Routes>
    </BrowserRouter>
);
