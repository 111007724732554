import React from "react";

const IqModal = ({
  id,
  title,
  text,
  btnText = null,
  asHtml = false,
  imageUrl = null,
}) => {
  return (
    <div id={id} className="modal modal-fixed-footer full-modal">
      <div className="modal-content center-align">
        <h4>{title}</h4>
        {imageUrl ? (
          <div className="row nm mt25 mb20">
            <div className="col s12">
              <img src={imageUrl} style={{ width: "50%" }} alt="" className="hide-on-med-and-down" />
              <img src={imageUrl} style={{ width: "100%" }} alt="" className="hide-on-large-only" />
            </div>
          </div>
        ) : null}
        {asHtml ? (
          <p
            className="secondary-color"
            dangerouslySetInnerHTML={{ __html: text }}
          ></p>
        ) : (
          <p className="secondary-color">{text}</p>
        )}
      </div>
      {btnText ? (
        <div className="modal-footer">
          <a href="#!" className="modal-close iq-btn btn-flat">
            {btnText}
          </a>
        </div>
      ) : (
        <div className="modal-footer"></div>
      )}
    </div>
  );
};

export default IqModal;
