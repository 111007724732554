import React, { useState } from "react";
import IqButton from "./UI/button/IqButton/IqButton";
import TextField from "./UI/input/TextField/TextField";

const ContactScreen = ({
  addAnswer,
  requireName,
  requireEmail,
  requirePhone,
}) => {
  let contactDataObj = {
    name: null,
    email: null,
    phone: null
  };

  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");

  let form = [];

  if (requireName == 1) {
    form.push(
      <TextField
        key={1}
        addAnswer={saveName}
        value={name}
        placeholder={"Ваше имя"}
        icon={"account_circle"}
      />
    );

    contactDataObj.name = "";
  }

  if (requireEmail == 1) {
    form.push(
      <TextField
        key={2}
        addAnswer={saveEmail}
        value={email}
        placeholder={"Email"}
        icon={"mail"}
      />
    );

    contactDataObj.email = "";
  }

  if (requirePhone == 1) {
    form.push(
      <TextField
        key={3}
        addAnswer={savePhone}
        value={phone}
        placeholder={"Номер телефона"}
        icon={"call"}
      />
    );

    contactDataObj.phone = "";
  }

  let [contactData, setContactData] = useState(contactDataObj);

  function saveName(answer) {
    setName(answer);
    let cd = contactData;
    cd.name = answer;
    setContactData(cd);
  }

  function saveEmail(answer) {
    setEmail(answer);
    let cd = contactData;
    cd.email = answer;
    setContactData(cd);
  }

  function savePhone(answer) {
    setPhone(answer);
    let cd = contactData;
    cd.phone = answer;
    setContactData(cd);
  }

  return (
    <div className="container center-align">
      <div className="row xl500">
        <div className="col s12">
          Оставьте, пожалуйста, свои контакты, чтобы мы могли связаться с Вами.
        </div>
      </div>
      <div className="row">
        <div className="col s12">{form}</div>
      </div>
      <div className="row">
        <div className="col s12">
          <IqButton
            action={addAnswer}
            answerData={contactData}
            isContactData={true}
          >
            {"Отправить"}
          </IqButton>
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
