import React from "react";

const QuestionResultBlock = ({
  question,
  correctAnswer,
  respondentAnswer,
  answerComment,
  isCorrect,
}) => {
  // console.log(correctAnswer);
  // console.log(isCorrect);
  // console.log(respondentAnswer);
  // console.log(question);
  // console.log('----');
  return (
    <div className="row">
      <div className="col s12">
        <div className="card light-shadow">
          <div className="card-content">
            <div className="row">
              <div className="col s12 l500">{question}</div>
            </div>
            <div className="row nm">
              <div
                className="col s12"
                style={correctAnswer ? { marginBottom: "10px" } : null}
              >
                <i
                  className="material-icons-outlined"
                  style={{ position: "absolute", marginTop: "-2px" }}
                  ref={(node) => {
                    if (node) {
                      isCorrect || !correctAnswer
                        ? node.style.setProperty(
                            "color",
                            "var(--success-color)",
                            "important"
                          )
                        : node.style.setProperty(
                            "color",
                            "var(--error-color)",
                            "important"
                          );
                    }
                  }}
                >
                  {isCorrect || !correctAnswer ? "check_circle" : "cancel"}
                </i>
                <span style={{ marginLeft: "30px" }}>
                  <span className="secondary-color">Ваш ответ:</span>{" "}
                  {respondentAnswer ? respondentAnswer : "—"}
                </span>
              </div>

              {correctAnswer ? (
                <div className="col s12">
                  <i
                    className="material-icons-outlined accent-color"
                    style={{ position: "absolute", marginTop: "-2px" }}
                  >
                    {"check"}
                  </i>
                  <span style={{ marginLeft: "30px" }}>
                    <span className="secondary-color">Правильный ответ:</span>{" "}
                    {correctAnswer}
                  </span>
                </div>
              ) : null}
              {answerComment ? (
                <div className="col s12 mt10">
                  <i
                    className="material-icons-outlined accent-color"
                    style={{ position: "absolute", marginTop: "-2px" }}
                  >
                    {"chat"}
                  </i>
                  <span style={{ marginLeft: "30px" }}>
                    <span className="secondary-color">Комментарий:</span>{" "}
                    {answerComment}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionResultBlock;
