import React from "react";
import M from "materialize-css/dist/js/materialize.min.js";

const TextField = ({
  addAnswer,
  placeholder = "Текстовое поле",
  isTextArea = false,
  value = "",
  icon = null,
  rerenderState,
}) => {
  // console.log(value);
  let randomId = "textField-" + Math.floor(Math.random() * 100000);

  return (
    <div className={"col s12 input-field"}>
      {icon ? <i className="material-icons-outlined prefix">{icon}</i> : ""}
      {isTextArea ? (
        <textarea
          id={randomId}
          className="materialize-textarea current-question-answer"
          onChange={(event) => addAnswer(event.target.value)}
          value={value}
          required
        />
      ) : (
        <input
          id={randomId}
          type="text"
          className="current-question-answer"
          onChange={(event) => addAnswer(event.target.value)}
          value={value}
          required
        />
      )}
      <label htmlFor={randomId}>{placeholder}</label>
    </div>
  );
};

export default TextField;
