import React from "react";

const IqButton = ({ action, answerData, children, isContactData = null }) => {
  function doSomething() {
    answerData ? action(answerData, isContactData) : action();
  }

  return (
    <div>
      {children ? (
        <button className={"btn btn-large btn-flat iq-btn"} onClick={doSomething} style={{ marginBottom: 20 }}>
          {children}
        </button>
      ) : (
        null
      )}
    </div>
  );
};

export default IqButton;
