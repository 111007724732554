import React from "react";
import IqButton from "./UI/button/IqButton/IqButton";
import SecondaryButton from "./UI/button/SecondaryButton/SecondaryButton";
import QuestionCounter from "./UI/questionCounter/QuestionCounter";
import * as utils from "./utils.js";

const ContentBeforeScreen = ({
  beforeElements,
  curBeforeElement,
  startQuiz,
  nextBeforeElement,
  prevBeforeElement,
}) => {
  utils.setBackground();

  return (
    <div className="container center-align">
      <div className="row mb30">
        <div className="col s4 left-align">
          {curBeforeElement > 0 ? (
            <SecondaryButton action={prevBeforeElement}>
              ← назад
            </SecondaryButton>
          ) : null}
        </div>
        <div className="col s4">
          <QuestionCounter
            currentQuestion={beforeElements ? curBeforeElement + 1 : ""}
            totalQuestions={beforeElements ? beforeElements.length : ""}
          />
        </div>
        <div className="col s4"></div>
      </div>
      <div className="row">
        <div
          className="col s12"
          dangerouslySetInnerHTML={{
            __html:
              curBeforeElement < beforeElements.length
                ? beforeElements[curBeforeElement].content
                : null,
          }}
        ></div>
      </div>
      <div className="row mt35">
        <div className="col s12">
          <IqButton
            action={
              curBeforeElement >= beforeElements.length - 1
                ? startQuiz
                : nextBeforeElement
            }
          >
            {"Продолжить"}
          </IqButton>
        </div>
      </div>
    </div>
  );
};

export default ContentBeforeScreen;
