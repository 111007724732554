import axios from "axios";
import React, { useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import QuestionScreen from "../components/QuestionScreen";
import InfoScreen from "../components/InfoScreen";
import ContactScreen from "../components/ContactScreen";
import * as utils from "../components/utils.js";
import M from "materialize-css/dist/js/materialize.min.js";
import ContentBeforeScreen from "../components/ContentBeforeScreen";
import IqModal from "../components/UI/modal/IqModal/IqModal";

function Quiz() {
  const devMode = window.settings.dev_mode;
  const backSite = utils.getBackSite(devMode);
  const wsSite = utils.getWsSite(devMode);
  let socket = useRef(null);

  const params = useParams();
  const [getParams, setGetParams] = useSearchParams();

  let quizId = params.quizId;
  let respondentId = getParams.get("rid");
  let token = getParams.get("t");
  let theme = getParams.get("theme");

  let [questions, setQuestions] = useState(null);
  let [contentBeforeElements, setContentBeforeElements] = useState(null);
  let [curQuestion, setCurrentQuestion] = useState(0);
  let [curBeforeElement, setBeforeElement] = useState(0);
  let [quizAnswers, setQuizAnswers] = useState({});

  let [infoScreenTitle, setInfoScreenTitle] = useState(null);
  let [infoScreenContent, setInfoScreenContent] = useState(null);
  let [finalMessage, setFinalMessage] = useState(null);
  let [showResults, setShowResults] = useState(null);
  let [infoScreenBtnText, setInfoScreenBtnText] = useState(null);

  let [requireName, setRequireName] = useState(null);
  let [requireEmail, setRequireEmail] = useState(null);
  let [requirePhone, setRequirePhone] = useState(null);

  let [currentScreen, setScreen] = useState("InfoScreen:start");
  let [quizResultsArr, setQuizResultsArr] = useState(null);

  let [backgroundUrl, setBackgroundUrl] = useState(null);
  let [logoUrl, setLogoUrl] = useState(null);
  let [bonusUrl, setBonusUrl] = useState(null);

  let [currentAnswerComment, setCurrentAnswerComment] = useState("");
  let [currentAnswerCommentImgUrl, setCurrentAnswerCommentImgUrl] =
    useState("");
  let [quizOnlineStateData, setQuizOnlineStateData] = useState(null);
  let [quizHasStarted, setQuizHasStarted] = useState(0);

  let [connectionId, setConnectionId] = useState(null);
  let [roomId, setRoomId] = useState(null);

  let [onlineQuizResultsText, setOnlineQuizResultsText] = useState("");
  let [modalWaitingText, setModalWaitingText] = useState("");
  let [quizStep, setQuizStep] = useState("");

  function addQuizAnswer(answer, isContactData = null) {
    // console.log(answer);
    if (typeof answer === "string" || answer instanceof String) {
      answer = answer.trim();
    }

    if (!answer) {
      M.toast({ html: "Не получен ответ на вопрос" });
      return;
    }
    if (Array.isArray(answer)) {
      if (answer.length == 0) {
        M.toast({ html: "Необходимо выбрать ответ" });
        return;
      }
      answer = answer.join(",");
    }

    let answeredQuestionsCounter = 0;
    let newQuizAnswers = {};
    let contactData = null;

    if (isContactData) {
      // if contact form
      // console.log(answer);
      contactData = answer;
      if (
        !contactData.hasOwnProperty("name") ||
        !contactData.hasOwnProperty("email") ||
        !contactData.hasOwnProperty("phone")
      ) {
        M.toast({
          html: "Не удается прочитать необходимые поля с контактной формы",
        });
        return;
      }

      if (typeof contactData.name === "string") {
        contactData.name = contactData.name.trim();
      }
      if (typeof contactData.email === "string") {
        contactData.email = contactData.email.trim();
      }
      if (typeof contactData.phone === "string") {
        contactData.phone = contactData.phone.trim();
      }

      if (contactData.name == "") {
        M.toast({
          html: "Укажите имя",
        });
        return;
      }

      if (contactData.email == "") {
        M.toast({
          html: "Укажите email",
        });
        return;
      }

      if (contactData.phone == "") {
        M.toast({
          html: "Укажите номер телефона",
        });
        return;
      }

      newQuizAnswers = quizAnswers;
      for (const key in newQuizAnswers) {
        answeredQuestionsCounter++;
      }

      addStep("QUIZ_FILLED_FORM");
    } else {
      if (answer !== "/next") {
        // manual mode
        newQuizAnswers = {
          ...quizAnswers,
          [questions[curQuestion].id]: answer,
        };

        // console.log(newQuizAnswers);
        // console.log(curQuestion);
        // console.log(questions);
        setQuizAnswers(newQuizAnswers);
        quizAnswers = newQuizAnswers;
        // console.log(quizAnswers);

        for (const key in newQuizAnswers) {
          answeredQuestionsCounter++;
        }

        if (connectionId && roomId) {
          if (questions.length !== curQuestion + 1) {
            openOnlineWaitingModal(
              "Вы ответили на вопрос, ждем остальных участников"
            );
          } else {
            openOnlineWaitingModal(
              "Вы ответили на все вопросы, ждем получения результатов всех участников"
            );
          }

          sendCommand(
            {
              state: "waiting",
              value: connectionId,
            },
            socket.current,
            connectionId,
            roomId
          );
        }

        if (
          (questions[curQuestion].answer_comment ||
            questions[curQuestion].answer_comment_img_url) &&
          !connectionId &&
          !roomId
        ) {
          openAnswerCommentAnswer();
        }
      } else {
        // auto mode
        // ! переопределяет ответы, которые были внесены юзером вручную и не добавляет последний ответ викторины
        // ! возможно, лучше после всех вопросов вместо end отправить state с последним вопросом , а потом end
        // ? что делать?
        // !!! ВОЗМОЖНО ЭТО УСЛОВИЕ ЛУЧШЕ УБРАТЬ и не передавать _no_answer
        if (!quizAnswers.hasOwnProperty(questions[curQuestion].id)) {
          newQuizAnswers = {
            ...quizAnswers,
            [questions[curQuestion].id]: "—",
          };
          setQuizAnswers(newQuizAnswers);
          quizAnswers = newQuizAnswers;
        }

        // console.log(newQuizAnswers);
        // console.log(curQuestion + 1, questions.length);
        // console.log(questions);
        if (questions.length === curQuestion + 1) {
          // console.log("открыть окно");
          openOnlineWaitingModal(
            "Вы ответили на все вопросы, ждем получения результатов всех участников"
          );
        }
      }

      addStep("QUIZ_ANSWERED_" + (curQuestion + 1));
    }

    if (answeredQuestionsCounter >= questions.length) {
      if (
        (requireName == 1 || requireEmail == 1 || requirePhone == 1) &&
        currentScreen !== "ContactScreen" &&
        !connectionId &&
        !roomId
      ) {
        addStep("QUIZ_ANSWERED_AQ");
        setScreen("ContactScreen");
        return;
      }

      if (requireName != 1 && requireEmail != 1 && requirePhone != 1) {
        addStep("QUIZ_ANSWERED_AQ");
      }

      if (
        (requireName == 1 || requireEmail == 1 || requirePhone == 1) &&
        connectionId &&
        roomId
      ) {
        addStep("QUIZ_ANSWERED_AQ");
      }

      setInfoScreenTitle(null);
      setScreen("InfoScreen:end");
      window.scrollTo(0, 0);

      let submitData = {
        answers: newQuizAnswers,
        contact_data: contactData,
      };

      // console.log(submitData);
      if (!connectionId && !roomId) {
        // console.log("результаты отправлены как в обычном режиме");
        submitQuizResults(submitData);
      }
    } else {
      if (connectionId && roomId) {
        // console.log(quizOnlineStateData);
        setQuestion(quizOnlineStateData.value);
        if (answer === "/next") {
          if (questions.length !== curQuestion) {
            // console.log("закрываем окно", questions.length, curQuestion + 1);
            closeOnlineWaitingModal();
          }
        }
      } else {
        setQuestion(curQuestion + 1);
      }
    }

    window.scrollTo(0, 0);
  }

  async function submitQuizResults(submitData) {
    let allParams = [];
    getParams.forEach((value, key) => {
      allParams.push([key, value]);
    });
    // console.log(allParams);

    let submitResponse = (
      await axios.post(backSite + "/api/submit_quiz_results", {
        quiz_id: params.quizId,
        respondent_id: getParams.get("rid"),
        token: getParams.get("t"),
        data: submitData,
        cid: getParams.get("cid"),
        room: getParams.get("room"),
        search_params: allParams
      })
    ).data;

    // console.log(submitResponse);
    if (!submitResponse.response_code && devMode) {
      M.toast({ html: submitResponse.message });
    }

    let quizResultsData = submitResponse.result;
    if (quizResultsData) {
      // здесь нужно сконфигурировать:
      // resultsTitle
      // resultsContent
      // setQuizResultsArr() - задать массив вопросов с ответами для отображения на экране результатов

      // по умолчанию результат в процентах
      let resultsTitle =
        "Ваш результат — " +
        utils.formatNumber(quizResultsData.right_percent, true) +
        "%";

      // по умолчанию благодарим
      let resultsContent = "Благодарим за прохождение квиза!";
      // по умолчанию показываем ответы на вопросы и сами вопросы на финальном экране
      let qResultsArr = quizResultsData.qa;

      // если в системе указано, что показывать результаты квиза и % не нужно или нужно показать персонализированный ответ, то выводить
      // ниже все вопросы и ответы не нужно
      if (showResults == 0 || quizResultsData.personal_response.content) {
        qResultsArr = null; // service settings secondary
      }

      // если не нужно показывать результаты или есть запрограммированное статичное сообщение для экрана результатов
      // то заголовок просто "спасибо"
      if (showResults == 0 || finalMessage) resultsTitle = "Спасибо!";
      // если есть персонализированный заголовок, нужно показать его
      if (quizResultsData.personal_response.header)
        resultsTitle = quizResultsData.personal_response.header;
      // если есть статическое сообщение для контента экрана результатов, покажем его
      if (finalMessage) resultsContent = finalMessage;
      // если есть результаты персонализированной калькуляции, то добавим в контент (заменяет благодарность по умолчанию)
      if (quizResultsData.personal_response.calc)
        resultsContent = quizResultsData.personal_response.calc + "<br>";
      // если есть персонализированный контент, то добавим его после калькуляции
      // но при этом если ранее не было добавлено калькуляции (не задано настройками)
      // то уберем благодарность, которая установлена по умолчанию, чтобы в начале контента не шла благодарность
      if (quizResultsData.personal_response.content) {
        if (!quizResultsData.personal_response.calc) {
          resultsContent = "";
        }
        resultsContent += quizResultsData.personal_response.content;
      }

      // теперь конфиг, который имеет самый высокий приоритет, так как конфиг может быть выдан в ответ на отправку вебхука
      // конфиг имеет приоритет в своих настройках, поэтому если там есть данные, то
      // эти настройки переопределят предыдущие, заданные в системе в админке iq
      let resultScreenConfig = quizResultsData.config
        ? quizResultsData.config
        : {};

      if (
        (resultScreenConfig.hasOwnProperty("message") &&
          resultScreenConfig.message) ||
        getParams.get("no_message") == 1
      ) {
        resultsTitle = "Спасибо!";
        resultsContent = resultScreenConfig.message;
      }

      if (
        (resultScreenConfig.hasOwnProperty("show_results") &&
          resultScreenConfig.show_results === false) ||
        getParams.get("show_results") == 0
      ) {
        qResultsArr = null; // webhook response priority
      }

      setInfoScreenTitle(resultsTitle);
      setInfoScreenContent(resultsContent);
      setQuizResultsArr(qResultsArr);
    } else {
      setInfoScreenTitle("Спасибо!");
      setInfoScreenContent(null);
    }
  }

  function prevQuestion() {
    setQuestion(curQuestion - 1);
  }

  const setQuestion = (questionArrIndex) => {
    setCurrentQuestion(questionArrIndex);
    curQuestion = questionArrIndex;
  };

  const startQuiz = () => {
    addStep("QUIZ_STARTED");
    setQuizHasStarted(1);
    // setScreen("QuestionScreen");
    if (contentBeforeElements && contentBeforeElements.length > 0) {
      setScreen("ContentBeforeScreen");
    } else {
      setScreen("QuestionScreen");
    }

    window.scrollTo(0, 0);
  };

  const forceStartQuiz = () => {
    setScreen("QuestionScreen");
    window.scrollTo(0, 0);
  };

  async function addStep(step) {
    setQuizStep(step);

    let addStepResponse = (
      await axios.post(backSite + "/api/add_step", {
        quiz_id: params.quizId,
        step: step,
      })
    ).data;

    // console.log(addStepResponse);
  }

  React.useEffect(() => {
    utils.setTheme(theme);

    getQuiz();

    addStep("QUIZ_OPENED");
  }, []);

  React.useEffect(() => {
    setConnectionId(getParams.get("cid"));
    connectionId = getParams.get("cid");
    setRoomId(getParams.get("room"));
    roomId = getParams.get("room");
    // console.log(connectionId, roomId);
    let tm;
    if (connectionId && roomId) {
      wsConnect();
    }

    function wsConnect(isReconnect = false) {
      socket.current = new WebSocket(
        wsSite + "?cid=" + connectionId + "&room=" + roomId
      );

      // tm = setTimeout(() => {
      //   console.log("Имитация прерывания соединения");
      //   socket.current.close();
      // }, 10000);

      if (
        isReconnect &&
        quizOnlineStateData &&
        quizOnlineStateData.hasOwnProperty("state") &&
        quizOnlineStateData.hasOwnProperty("value")
      ) {
        if (
          quizOnlineStateData.state == "question" &&
          quizOnlineStateData.value !== null
        ) {
          // console.log("УСТАНОВКА ВОПРОСА");
          setQuestion(quizOnlineStateData.value);
          closeOnlineWaitingModal();
        }
      }

      // console.log(quizOnlineStateData);
      socket.current.onopen = function (e) {
        // alert("[open] Соединение установлено");
        // alert("Отправляем данные на сервер");
        // console.log(quizStep);
        if (quizStep == "QUIZ_OPENED" || isReconnect) {
          sendCommand(
            {
              state: "new_connection",
              value: connectionId,
            },
            socket.current,
            connectionId,
            roomId
          );

          if (
            Object.keys(quizAnswers).length > 0 &&
            questions &&
            quizOnlineStateData.state === "question"
          ) {
            let isWaiting = false;
            let curQuestionData = questions[curQuestion];
            for (let key in quizAnswers) {
              if (curQuestionData.id === key) {
                isWaiting = true;
                break;
              }
            }

            if (isWaiting) {
              openOnlineWaitingModal(
                "Вы ответили на вопрос, ждем остальных участников"
              );
              sendCommand(
                {
                  state: "waiting",
                  value: connectionId,
                },
                socket.current,
                connectionId,
                roomId
              );
            }
          }
          // console.log(quizAnswers, questions, curQuestion);
        }
      };
      socket.current.onmessage = function (event) {
        // alert(`[message] Данные получены с сервера: ${event.data}`);
        let stateData = JSON.parse(event.data);
        // console.log(stateData);
        if (
          stateData.state === "new_connection" ||
          stateData.state === "all_participants" ||
          stateData.state === "waiting"
        ) {
          // ignoring these types of message
          return;
        } else if (stateData.state === "results") {
          closeOnlineWaitingModal();

          let resArr = stateData.value;
          let resultsText = "";
          let resCounter = 1;
          resArr.sort((a, b) => Number(b.result) - Number(a.result));
          resArr.forEach((resElement) => {
            if (!resElement.cid.includes("admin-")) {
              resultsText +=
                resCounter +
                ". " +
                (resElement.cid === connectionId ? "(Вы) " : "") +
                resElement.cid +
                " — " +
                utils.formatNumber(Number(resElement.result).toFixed(2)) +
                "%<br>";
              resCounter++;
            }
          });
          setOnlineQuizResultsText(resultsText);
          openResultsModal(); // pass results to modal
          return;
        }
        // console.log(stateData);
        setQuizOnlineStateData(stateData);
        quizOnlineStateData = stateData;
        if (stateData.state == "question") {
          if (stateData.value == 0 && !quizHasStarted) {
            startQuiz();
          } else {
            addQuizAnswer("/next");
          }
        } else if (stateData.state == "end") {
          setInfoScreenTitle(null);
          setScreen("InfoScreen:end");
          window.scrollTo(0, 0);

          let submitData = {
            answers: quizAnswers,
          };

          // console.log(submitData);

          // console.log("результаты отправлены в онлайн-режиме");
          submitQuizResults(submitData);
        }
        // setcq(JSON.parse(event.data));
      };
      socket.current.onclose = function (event) {
        // clearTimeout(tm);
        console.log(
          "Соединение закрыто, повторное подключение через 1 секунду",
          event.reason
        );
        setTimeout(function () {
          wsConnect(true);
        }, 1000);
        // if (event.wasClean) {
        // alert(
        //   `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
        // );
        // console.log(
        //   `[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`
        // );
        // } else {
        // например, сервер убил процесс или сеть недоступна
        // обычно в этом случае event.code 1006
        // console.log("[close] Соединение прервано");
        // }
      };
      socket.current.onerror = function (error) {
        // alert(`[error] Не удалось установить соединение с сервером`);
        console.error(
          "Socket encountered error: ",
          error.message,
          "Closing socket"
        );
        socket.current.close();
      };
    }
  }, [quizAnswers, socket, quizStep]);

  async function getQuiz() {
    try {
      let quiz = {};

      let quizInfoResponse = (await getQuizData()).data;
      // console.log(quizInfoResponse);
      if (!quizInfoResponse.response_code) {
        throw new Error(quizInfoResponse.message);
      }

      quiz.info = quizInfoResponse.result;

      let quizQuestionsResponse = (await getQuestions()).data;
      // console.log(quizQuestionsResponse);
      if (!quizQuestionsResponse.response_code) {
        throw new Error(quizQuestionsResponse.message);
      }

      quiz.questions = quizQuestionsResponse.result;

      // console.log(quiz);
      let btnBgColor = quiz.info.btn_bg_color;
      let btnTextColor = quiz.info.btn_text_color;
      let bgColor = quiz.info.bg_color;
      if (btnBgColor) {
        document.documentElement.style.setProperty(
          "--btn-bg-color",
          btnBgColor
        );
        document.documentElement.style.setProperty(
          "--btn-bg-hover-color",
          btnBgColor + "E0"
        );
        document.documentElement.style.setProperty(
          "--accent-color",
          btnBgColor
        );
      }
      if (btnTextColor) {
        document.documentElement.style.setProperty(
          "--text-primary-color",
          btnTextColor
        );
      }
      if (bgColor) {
        document.documentElement.style.setProperty("--bg-color", bgColor);
        document.documentElement.style.setProperty(
          "--surface-bg-color",
          utils.adjustColor(bgColor, 12)
        );
      }

      setBackgroundUrl(quiz.info.bg_url);
      setLogoUrl(quiz.info.logo_url);
      setBonusUrl(quiz.info.bonus_url);
      setFinalMessage(quiz.info.final_message);
      setShowResults(quiz.info.show_results);

      setRequireName(quiz.info.require_name);
      setRequireEmail(quiz.info.require_email);
      setRequirePhone(quiz.info.require_phone);

      if (quiz.info.watermark) {
        document.querySelector("#footer-content").innerHTML =
          '<a href="' +
          backSite +
          '" class="iq-tertiary-link iq-watermark" target="_blank" rel="noreferrer noopener">Сделано в IntraQuiz</a>';
      }

      let infoScreenContentText = quiz.info.description
        ? quiz.info.description
        : "";

      quiz.questions.sort((a, b) => a.order_num - b.order_num);
      // console.log(quiz.questions);
      let contentBeforeArr = [];
      quiz.questions.forEach((question) => {
        if (question.content_before) {
          contentBeforeArr.push({
            content: question.content_before,
            question_id: question.id,
            question_num: question.order_num,
          });
        }
      });
      for (let i = 0; i < contentBeforeArr.length; i++) {
        contentBeforeArr[i].is_last =
          i === contentBeforeArr.length - 1 ? true : false;
      }

      // console.log(contentBeforeArr);

      setContentBeforeElements(contentBeforeArr);
      setQuestions(quiz.questions);
      questions = quiz.questions;
      setInfoScreenTitle(quiz.info.name);
      if (quiz.questions.length != 0 && !connectionId && !roomId) {
        setInfoScreenBtnText("Начать квиз");
      }
      if (quiz.info.bonus_url) {
        infoScreenContentText +=
          '<div><img src="' +
          backSite +
          "/img/gift-card.png?v=2" +
          '" alt="Логотип" style="height: 70px; margin-top: 30px;" /></div>';
      }
      setInfoScreenContent(infoScreenContentText);
    } catch (err) {
      setInfoScreenTitle(err.message);
      // console.log(err.message);
    }
  }

  function getQuizData() {
    let queryParams = "";
    if (respondentId && token) {
      queryParams = "&respondent_id=" + respondentId + "&token=" + token;
    }
    return axios.get(
      backSite + "/api/v1/get_quiz/?qid=" + quizId + queryParams
    );
  }
  function getQuestions() {
    let queryParams = "";
    if (respondentId && token) {
      queryParams = "&respondent_id=" + respondentId + "&token=" + token;
    }
    return axios.get(
      backSite + "/api/v1/get_quiz_questions/?qid=" + quizId + queryParams
    );
  }

  function nextBeforeElement() {
    setBeforeElement(curBeforeElement + 1);
    window.scrollTo(0, 0);
  }

  function prevBeforeElement() {
    setBeforeElement(curBeforeElement - 1);
    window.scrollTo(0, 0);
  }

  function openAnswerCommentAnswer() {
    setCurrentAnswerCommentImgUrl(
      questions[curQuestion].answer_comment_img_url
    );
    setCurrentAnswerComment(questions[curQuestion].answer_comment);
    // console.log(questions[curQuestion].answer_comment_img_url);
    let modal = document.querySelector("#modal-answer-comment");
    let modalInstance = M.Modal.init(modal);
    modalInstance.open();
  }

  function openOnlineWaitingModal(modalText) {
    setModalWaitingText(modalText);
    let modal = document.querySelector("#modal-online-waiting");
    let modalInstance = M.Modal.init(modal, {
      dismissible: false,
      preventScrolling: false,
    });
    modalInstance.open();
    // console.log("открыто");
  }

  function openResultsModal() {
    let modal = document.querySelector("#modal-results");
    let modalInstance = M.Modal.init(modal, {
      preventScrolling: false,
    });
    modalInstance.open();
  }

  function closeOnlineWaitingModal() {
    let modal = document.querySelector("#modal-online-waiting");
    let modalInstance = M.Modal.getInstance(modal);
    if (modalInstance) {
      modalInstance.close();
      modalInstance.destroy();
    }
  }

  function sendCommand(commandObj, ws, cid, roomId) {
    if (!commandObj.hasOwnProperty("state")) {
      alert("В команде отсутствует состояние");
      return;
    }

    commandObj.cid = cid;
    commandObj.room = roomId;
    ws.send(JSON.stringify(commandObj));
  }

  return (
    <div className="App container">
      <div
        className="row center-align"
        style={{
          marginTop: 0,
          marginBottom:
            currentScreen == "QuestionScreen" ||
            currentScreen == "ContentBeforeScreen"
              ? 25
              : 40,
        }}
      >
        <div className="col s12">
          {/* <img
            src={require("../img/intraquiz-logo.png")}
            style={{ height: 30 }}
            alt="logo"
          /> */}
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          {currentScreen === "InfoScreen:start" ? (
            <InfoScreen
              title={infoScreenTitle}
              content={infoScreenContent}
              btnText={infoScreenBtnText}
              action={startQuiz}
              screenTag={currentScreen.split(":")[1]}
              data={null}
              theme={getParams.get("theme")}
              backgroundUrl={backgroundUrl}
              logoUrl={logoUrl}
            />
          ) : currentScreen === "InfoScreen:end" ? (
            <InfoScreen
              title={infoScreenTitle}
              content={infoScreenContent}
              btnText={bonusUrl ? "Открыть подарок!" : null}
              action={
                bonusUrl
                  ? () => {
                      window.location.replace(bonusUrl);
                    }
                  : null
              }
              screenTag={currentScreen.split(":")[1]}
              data={quizResultsArr}
              theme={getParams.get("theme")}
              backgroundUrl={backgroundUrl}
              logoUrl={logoUrl}
            />
          ) : currentScreen === "ContactScreen" ? (
            <ContactScreen
              addAnswer={addQuizAnswer}
              requireName={requireName}
              requireEmail={requireEmail}
              requirePhone={requirePhone}
            />
          ) : currentScreen === "ContentBeforeScreen" ? (
            <ContentBeforeScreen
              beforeElements={contentBeforeElements}
              curBeforeElement={curBeforeElement}
              startQuiz={forceStartQuiz}
              nextBeforeElement={nextBeforeElement}
              prevBeforeElement={prevBeforeElement}
            />
          ) : (
            <QuestionScreen
              questions={questions}
              curQuestion={curQuestion}
              addAnswer={addQuizAnswer}
              prevQuestion={prevQuestion}
              curAnswers={quizAnswers}
              connectionId={connectionId}
              roomId={roomId}
            />
          )}
        </div>
      </div>

      <IqModal
        id={"modal-answer-comment"}
        title={"Комментарий"}
        text={currentAnswerComment}
        imageUrl={currentAnswerCommentImgUrl}
        btnText={"Понятно"}
      />
      <IqModal
        id={"modal-online-waiting"}
        title={"Ожидание"}
        text={modalWaitingText}
      />
      <IqModal
        id={"modal-results"}
        title={"Результаты участников"}
        text={onlineQuizResultsText}
        btnText={"Закрыть"}
        asHtml={true}
      />
    </div>
  );
}

export default Quiz;
