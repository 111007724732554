import React from 'react';
import { Link } from "react-router-dom";
import './styles/App.css';
import axios from "axios";

function App() {
  // const params = new URLSearchParams();
  // params.append('login', 'karmazin10@inbox.ru');
  // params.append('password', 'X@tntxyiev2EVHK@');

  const api = axios.create({
    baseURL: 'http://syfer.test:8888',
    withCredentials: true,
    // headers: {
    // "Content-Type": "application/x-www-form-urlencoded"
    // }
  });

  // api.post("/connect/auth", {
  //   login: 'karmazin10@inbox.ru',
  //   password: 'X@tntxyiev2EVHK@'
  // }).then(function (response) {
  //   console.log(response);
  // })
  //   .catch(function (error) {
  //     console.log(error);
  //   });
  // api.post("/connect/token", {}, {
  //   headers: {
  //     // Authorization: `Bearer sdefe3e2737fye78fw8742fh784f2y7e8h287`,
  //     // 'X-XSRF-Token' : getCookie('nott_xsrf_token')
  //   }
  // }).then(function (response) {
  //   console.log(response.data);
  //   console.log(getCookie('nott_xsrf_token'));
  // })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  api.post("/events/get?eventId=063135295a7b8cb0ffe034b0772d18d9", {}, {
    headers: {
      // Authorization: `Bearer sdefe3e2737fye78fw8742fh784f2y7e8h287`,
      'X-XSRF-Token': getCookie('nott_xsrf_token')
    }
  }).then(function (response) {
    console.log(response.data);
    // console.log(getCookie('nott_xsrf_token'));
  })
    .catch(function (error) {
      console.log(error);
    });

  // console.log(getCookie('nott_xsrf_token'));

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  return (
    <div className="container">
      <div
        className="row center-align"
        style={{ marginTop: 15, marginBottom: 40 }}
      >
        <div className="col s12 xxxl500">
          <img src={require("./img/intraquiz-logo-light.png")} style={{ height: 30 }} alt="logo" />
        </div>
      </div>

      <div className="row center-align">
        <div className="col s12 center-align">
          <h1 className="hide-on-med-and-down">Это приложение для проведения викторин IntraQuiz</h1>
          <h3 className="hide-on-large-only">Это приложение для проведения викторин IntraQuiz</h3>
        </div>
      </div>
      <div className="row center-align">
        <div className="col s12">
          <Link to="/join"><button className="btn iq-btn btn-flat" style={{ marginTop: 0 }}>Подключиться к комнате</button></Link>
        </div>
        <div className="col s12 mt25">
          <a href="https://intraquiz.ru" className="iq-link">Перейти на главный сайт</a>
        </div>
      </div>
    </div >
  );
}

export default App;
