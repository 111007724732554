import React from "react";

const SecondaryButton = ({ action, children, isIcon = false }) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={action}>
      {isIcon ? (<i className="material-icons-outlined">{children}</i>) : children}
    </div>
  );
};

export default SecondaryButton;
