import React, { useMemo, useState } from "react";
import classes from "./VariantButton.module.css";
import M from "materialize-css/dist/js/materialize.min.js";

const VariantButton = ({
  variant,
  addAnswer,
  children,
  isMultiple,
  startBtnState,
  rerenderState,
  imgUrl,
}) => {
  let [btnClassType, setBtnClassType] = useState(
    isMultiple ? "variantButtonOutlined" : "variantButton"
  );

  // console.log(btnClassType);

  useMemo(() => {
    if (startBtnState !== btnClassType) {
      setBtnClassType(startBtnState);
    }
  }, [startBtnState, rerenderState]);

  const desktopVariantMaxLength = 40;
  let desktopSizeClass =
    children.length <= desktopVariantMaxLength ? "l6" : "l12";

  function chooseVariant() {
    // console.log(variant);
    let pressedState = addAnswer(variant); // 0 - unpressed, 1 - pressed
    if (isMultiple) {
      if (pressedState) {
        setBtnClassType("variantButton");
      } else {
        setBtnClassType("variantButtonOutlined");
      }
    }
  }

  return (
    <div
      className={"col " + desktopSizeClass + " s12"}
      style={{
        padding: 10,
        outline: "1px solid var(--divider-color)",
        outlineOffset: "-11px",
      }}
    >
      {imgUrl ? (
        <div
          className="row nm"
          onClick={chooseVariant}
          style={{ cursor: "pointer" }}
        >
          <div className="col s12">
            <img
              className="hide-on-med-and-down"
              src={imgUrl}
              style={{
                maxHeight: "289px",
                width: "100%",
                objectFit: "contain",
                verticalAlign: "bottom",
              }}
              alt="Изображение не загружено"
            />
            <img
              className="hide-on-large-only"
              src={imgUrl}
              style={{
                width: "100%",
                maxWidth: "100%",
                verticalAlign: "bottom",
              }}
              alt="Изображение не загружено"
            />
          </div>
        </div>
      ) : null}
      <button
        className={"col s12 " + classes[btnClassType]}
        onClick={chooseVariant}
      >
        {children}
      </button>
    </div>
  );
};

export default VariantButton;
