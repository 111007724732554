import React from "react";

const Page404 = () => {
  return (
    <div className="container center-align">
      <div className="row" style={{ marginTop: 15, marginBottom: 40 }}>
        <div className="col s12 xxxl500">
          <img
            src={require("../img/intraquiz-logo-light.png")}
            style={{ height: 30 }}
            alt="logo"
          />
        </div>
      </div>

      <div className="col s12">
        <h1>Ошибка 404</h1>
      </div>
      <div className="col s12">
        Страницы не существует, но мы верим, вы найдете, что ищете
      </div>
    </div>
  );
};

export default Page404;
