import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as utils from "../components/utils.js";
import M from "materialize-css/dist/js/materialize.min.js";

const Join = () => {
  const devMode = window.settings.dev_mode;

  const navigate = useNavigate();

  const [getParams, setGetParams] = useSearchParams();
  let [cq, setcq] = useState(0);
  let [roomCode, setRoomCode] = useState("");
  const backSite = utils.getBackSite(devMode);
  const quizSite = utils.getQuizSite(devMode);

  // console.log(getParams.get('connect'));
  let connectCode = getParams.get('connect');
  if (connectCode) {
    connectRoom(connectCode);
    return;
  }

  async function connectRoom(code = null) {
    let connectResponse = (
      await axios.post(backSite + "/api/connect_room", {
        code: roomCode ? roomCode : code,
      })
    ).data;

    // console.log(connectResponse);
    if (!connectResponse.response_code) {
      M.toast({ html: connectResponse.message });
      return;
    }

    let roomData = connectResponse.result;
    if (roomData.status != 1) {
      let failureReason =
        roomData.status == 2
          ? " (викторина уже началась)"
          : " (комната закрыта)";
      M.toast({
        html: "К данной комнате невозможно присоединиться" + failureReason,
      });
      return;
    }

    // if ok
    let redirectPath =
      "/q/" +
      roomData.quiz_id +
      "?cid=" +
      roomData.connection_id +
      "&room=" +
      roomData.id;
    // console.log(redirectPath);
    navigate(redirectPath);
  }

  return (
    <div>
      <div
        className="row center-align container"
        style={{ marginTop: 15, marginBottom: 40 }}
      >
        <div className="col s12">
          <img
            src={require("../img/intraquiz-logo-light.png")}
            style={{ height: 30 }}
            alt="logo"
          />
        </div>
      </div>
      <div className="row center-align container">
        <div className="col s12 xl500">Подключиться к комнате по коду</div>
        <div className="col l4"></div>
        <div className="input-field col s12 l4">
          <input
            id="connect"
            name="connect"
            type="text"
            inputmode="decimal"
            placeholder="Введите код подключения"
            value={roomCode}
            onChange={(e) => setRoomCode(e.target.value)}
          />
        </div>
        <div className="col l4"></div>
        <div className="col s12 mt15">
          <button className="iq-btn btn btn-flat" onClick={connectRoom}>
            Подключиться
          </button>
        </div>
      </div>
    </div>
  );
};

export default Join;
