import React from "react";
import IqButton from "./UI/button/IqButton/IqButton";
import QuestionResultBlock from "./UI/quesionResultBlock/QuestionResultBlock";
import * as utils from "./utils.js";
import M from "materialize-css/dist/js/materialize.min.js";

const InfoScreen = ({
  title,
  content,
  btnText,
  action,
  screenTag,
  data,
  theme,
  backgroundUrl,
  logoUrl,
}) => {
  let elements = [];

  // if (screenTag === "start") {
  utils.setBackground(backgroundUrl, theme);
  // } else {
  //   utils.setBackground();
  // }

  if (Array.isArray(data)) {
    data.sort((a, b) => a.order_num - b.order_num);

    if (screenTag === "end") {
      let elKey = 1;
      data.forEach((element) => {
        // console.log(element);

        let correctAnswerArr = element.correct_answer.split(",");
        let correctAnswerTextArr = [];
        for (let i = 0; i < correctAnswerArr.length; i++) {
          correctAnswerTextArr.push(element["answer_" + correctAnswerArr[i]]);
        }

        let respondentAnswerTextArr = [];
        if (element.type == 2) {
          respondentAnswerTextArr.push(element.respondent_answer);
        } else {
          let respondentAnswerArr = element.respondent_answer.split(",");
          for (let i = 0; i < respondentAnswerArr.length; i++) {
            respondentAnswerTextArr.push(
              element["answer_" + respondentAnswerArr[i]]
            );
          }
        }

        // console.log(correctAnswerTextArr);

        elements.push(
          <QuestionResultBlock
            question={elKey + ". " + element.question}
            correctAnswer={
              correctAnswerTextArr.indexOf(undefined) == -1
                ? correctAnswerTextArr.join("; ")
                : null
            }
            respondentAnswer={respondentAnswerTextArr.join("; ")}
            isCorrect={element.is_correct}
            answerComment={element.answer_comment}
            key={elKey}
          />
        );
        elKey++;
      });
    }
  }

  function copyQuizUrl() {
    let url = utils.getOriginUrl();

    if (!navigator.clipboard) {
      let copyResult = utils.fallbackCopyTextToClipboard(url);
      if (copyResult) {
        M.toast({ html: "Скопировано!" });
      } else {
        M.toast({ html: "Не удалось скопировать ссылку на квиз :(" });
      }
      console.log(copyResult);
      return;
    }
    navigator.clipboard.writeText(url).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        M.toast({ html: "Скопировано!" });
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
        M.toast({ html: "Не удалось скопировать ссылку на квиз :(" });
      }
    );
  }

  return (
    <div className="container">
      {!title ? (
        <div className="row center-align">
          <div className="col s12">
            <div className="preloader-wrapper active">
              <div className="spinner-layer">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div>
                <div className="gap-patch">
                  <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          {logoUrl ? (
            <div className="col s12 center-align">
              <img
                src={logoUrl}
                alt="Логотип"
                style={{ height: 40, marginBottom: 27 }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="col s12">
            <div className="row center-align">
              <div className="col s12 xl500">{title}</div>
            </div>

            <div className="row center-align" style={{ marginBottom: 30 }}>
              <div
                className="col s12 secondary-color l300"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </div>

            <div className="row center-align nm">
              <div className="col s12">
                <IqButton action={action}>{btnText}</IqButton>
              </div>
            </div>

            {screenTag === "end" ? (
              <div className="row center-align mb30">
                <div className="col s12">
                  <button className="btn iq-btn btn-flat" onClick={copyQuizUrl}>
                    <i className="material-icons left">ios_share</i>Поделиться
                    квизом
                  </button>
                </div>
              </div>
            ) : null}

            <div> {screenTag === "end" ? elements : ""}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoScreen;
