import React, { useState } from "react";
import IqButton from "./UI/button/IqButton/IqButton";
import SecondaryButton from "./UI/button/SecondaryButton/SecondaryButton";
import VariantButton from "./UI/button/VariantButton/VariantButton";
import TextField from "./UI/input/TextField/TextField";
import QuestionCounter from "./UI/questionCounter/QuestionCounter";
import * as utils from "./utils.js";
import M from "materialize-css/dist/js/materialize.min.js";
import IqModal from "./UI/modal/IqModal/IqModal";

const QuestionScreen = ({
  questions,
  curQuestion,
  addAnswer,
  prevQuestion,
  curAnswers,
  connectionId,
  roomId
}) => {
  utils.setBackground();

  let [rerenderState, setRerenderState] = useState();

  let targetAnswerObject = null;
  // console.log(curQuestion);
  if (questions[curQuestion].type == 2) {
    targetAnswerObject = ""; //curAnswers[questions[curQuestion].id] ? curAnswers[questions[curQuestion].id] : "";
  } else {
    targetAnswerObject = [];
  }
  // console.log(targetAnswerObject);
  let [respondentAnswersData, setRespondentAnswers] =
    useState(targetAnswerObject);

  let [currentHint, setCurrentHint] = useState("");

  React.useEffect(() => {
    let tf = document.querySelectorAll(".current-question-answer")[0];
    if (tf) {
      let tfValue = curAnswers[questions[curQuestion].id]
        ? curAnswers[questions[curQuestion].id]
        : "";
      tf.value = tfValue;
      setRespondentAnswers(tfValue);
      M.updateTextFields();
    }
  }, [rerenderState, curQuestion]);

  // respondentAnswersData = targetAnswerObject;

  // let [respondentTextAnswer, setRespondentTextAnswer] = useState("");
  // console.log(questions);
  // console.log(curQuestion);

  let currentQuestionPosition = curQuestion;
  let answers = {};
  let variantsArr = [];

  if (questions) {
    if (curQuestion < 0) {
      currentQuestionPosition = 0;
    } else if (curQuestion >= questions.length) {
      currentQuestionPosition = questions.length - 1;
    }

    for (const key in questions[currentQuestionPosition]) {
      let [ans, num] = key.split("_");
      if (ans === "answer" && utils.isNumber(num)) {
        answers[num] = questions[currentQuestionPosition][key];
      }
    }
    // console.log(answers);

    // console.log(questions);

    if (questions[curQuestion].type == 1) {
      for (const num in answers) {
        if (answers[num]) {
          if (questions[curQuestion].multiple) {
            variantsArr.push(
              <VariantButton
                variant={num}
                key={num}
                addAnswer={pushAnswer}
                isMultiple={true}
                startBtnState={"variantButtonOutlined"}
                rerenderState={rerenderState}
                imgUrl={questions[curQuestion]["a" + num + "_img_url"]}
              >
                {answers[num]}
              </VariantButton>
            );
          } else {
            variantsArr.push(
              <VariantButton
                variant={num}
                key={num}
                addAnswer={addAnswer}
                isMultiple={false}
                startBtnState={"variantButton"}
                rerenderState={rerenderState}
                imgUrl={questions[curQuestion]["a" + num + "_img_url"]}
              >
                {answers[num]}
              </VariantButton>
            );
          }
        }
      }
    } else if (questions[curQuestion].type == 2) {
      variantsArr.push(
        <TextField
          key={1}
          addAnswer={saveTextAnswer}
          isTextArea={1}
          value={respondentAnswersData}
          placeholder={"Ваш ответ пишите здесь"}
          rerenderState={rerenderState}
        />
      );
    }

    // console.log(answersArr);
  }

  function pushAnswer(answer) {
    let answersArr = respondentAnswersData;
    if (!Array.isArray(answersArr)) {
      answersArr = [];
    }
    let matchIndex = null;
    for (let i = 0; i < answersArr.length; i++) {
      if (answersArr[i] == answer) {
        matchIndex = i;
        break;
      }
    }

    let response = 1;
    if (!utils.isNumber(matchIndex)) {
      answersArr.push(answer);
    } else {
      answersArr.splice(matchIndex, 1);
      response = 0;
    }
    setRespondentAnswers(answersArr);

    return response;
  }

  function saveTextAnswer(answer) {
    setRespondentAnswers(answer);
    // console.log(respondentTextAnswer);
  }

  function openHintModal() {
    setCurrentHint(questions[currentQuestionPosition].hint);
    let modal = document.querySelector("#modal-hint");
    let modalInstance = M.Modal.init(modal);
    modalInstance.open();
  }

  // console.log(currentQuestionPosition);

  return (
    <div className="container center-align">
      <div className="row">
        <div className="col s4 left-align">
          {(curQuestion > 0 && !connectionId && !roomId) ? (
            <SecondaryButton
              action={() => {
                prevQuestion();
                setRerenderState(Math.random());
              }}
            >
              ← назад
            </SecondaryButton>
          ) : null}
        </div>
        <div className="col s4">
          <QuestionCounter
            currentQuestion={questions ? curQuestion + 1 : ""}
            totalQuestions={questions ? questions.length : ""}
          />
        </div>
        <div className="col s4 right-align">
          {questions[currentQuestionPosition].hint ? (
            <SecondaryButton isIcon={true} action={openHintModal}>
              info
            </SecondaryButton>
          ) : null}
        </div>
      </div>
      <div className="row xl500">
        <div className="col s12">
          {!questions ? (
            <div>Нет данных о вопросах викторины</div>
          ) : (
            <div>{questions[currentQuestionPosition].question}</div>
          )}
        </div>
      </div>
      {questions[currentQuestionPosition].question_img_url ? (
        <div className="row mt30 mb30">
          <div className="col s12">
            <img
              src={questions[currentQuestionPosition].question_img_url}
              className="hide-on-med-and-down"
              style={{
                maxHeight: "300px",
                width: "100%",
                objectFit: "contain",
              }}
              alt="Изображение не загружено"
            />
            <img
              src={questions[currentQuestionPosition].question_img_url}
              className="hide-on-large-only"
              style={{
                width: "100%",
                maxWidth: "100%",
                verticalAlign: "bottom",
              }}
              alt="Изображение не загружено"
            />
          </div>
        </div>
      ) : null}

      {questions[curQuestion].multiple ? (
        <div className="row mt30 mb30">
          <div className="col s12">
            <span
              className="alert-message"
              style={{
                padding: 10,
                borderRadius: 12,
                color: "var(--alert-color)",
              }}
            >
              Выберите один или несколько
            </span>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col s12">{variantsArr}</div>
      </div>

      <div className="row">
        <div className="col s12">
          {questions[curQuestion].multiple ||
          questions[curQuestion].type == 2 ? (
            <IqButton
              action={(data) => {
                addAnswer(data);
                saveTextAnswer("");
                setRerenderState(Math.random());
              }}
              answerData={respondentAnswersData}
            >
              {"Следующий вопрос"}
            </IqButton>
          ) : null}
        </div>
      </div>
      <IqModal
        id={"modal-hint"}
        title={"Подсказка"}
        text={currentHint}
        btnText={"Закрыть"}
      />
    </div>
  );
};

export default QuestionScreen;
